i, u {
  cursor: pointer;
} 

 
/* Default styles */
body {
  margin: 0;
  overflow: hidden;
  background-color: #ebebeb;
  --player-offset: 100px;
  --bottom-bar-offset: 48px;
  --bottom-menu-offset: 0px;
  --top-bar-offset: 76px;
  --tiny-button-size: 24px;
  --big-button-size: 36px;
  --color-primary: #053d82;
  --max-container-width: 913px;
}

/* Styles for mobile viewport */
@media (max-width: 768px) {
  body {
    --player-offset: 80px;
    --bottom-menu-offset: 56px;
    /* --bottom-bar-offset: 48px; */
    --top-bar-offset: 72px;
    /* --tiny-button-size: 24px; */
  }

  
}

@media screen and (max-width: 912px) and (orientation: landscape) { 
  body {
    --top-bar-offset: 0px;
    --player-offset: 88px;
    --bottom-menu-offset: 0px;
  }

  i.fa-regular.big, 
  i.fa-solid.big {
    height: var(--big-button-size);
    width: var(--big-button-size);
    min-width: var(--big-button-size);
  }
  
}

.item-left {
  text-align: right;
}

.item-right {
  text-align: left;
} 


* {
  box-sizing: border-box;
}

i.fa-regular, 
i.fa-solid {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: var(--tiny-button-size);
  width: var(--tiny-button-size);
  min-width: var(--tiny-button-size);
  /* border: solid 1px #ebebeb; */
  border-radius: 50%; 
  color: var(--color-primary);
}
i.fa-solid:hover {
  border: solid 1px #bebebe;
}

i:hover,
i.red {
  color: red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.coverflow-container {
  perspective: 1000px;
}

.coverflow-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 200px;
  transform-style: preserve-3d;
  transition: transform 1s ease;
}

.coverflow-item:nth-child(1) {
  transform: translateX(-350px) translateZ(-200px) rotateY(45deg);
}

.coverflow-item:nth-child(2) {
  transform: translateX(-175px) translateZ(-100px) rotateY(22.5deg);
}

.coverflow-item:nth-child(3) {
  transform: translateZ(0);
}

.coverflow-item:nth-child(4) {
  transform: translateX(175px) translateZ(-100px) rotateY(-22.5deg);
}

.coverflow-item:nth-child(5) {
  transform: translateX(350px) translateZ(-200px) rotateY(-45deg);
}


/* 
.coverflow {
  position: relative;
  width: 500px;
  height: 300px;
  perspective: 1000px;
}

.coverflow .flow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0% 50%;
  transition: transform 1s;
}

.coverflow .flow:first-child {
  transform: translateZ(0);
}

.coverflow .flow:nth-child(2) {
  transform: translateZ(-100px) translateX(-100px) rotateY(-45deg);
}

.coverflow .flow:nth-child(3) {
  transform: translateZ(-200px) translateX(-200px) rotateY(-90deg);
}

.coverflow .flow:nth-child(4) {
  transform: translateZ(-100px) translateX(-300px) rotateY(-135deg);
}

.coverflow .flow:last-child {
  transform: translateZ(0);
} */
